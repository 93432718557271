<template>
  <page>
    <template #navigation>
      <span
        class="
          text-xs text-pink-700
          uppercase
          whitespace-nowrap
          hidden
          xs:block
        "
        >{{ $t('contacts.settings') }}</span
      >
    </template>
    <header-settings name="contacts" />
  </page>
</template>

<script>
import HeaderSettings from '@/components/HeaderSettings'

export default {
  components: { 'header-settings': HeaderSettings }
}
</script>
